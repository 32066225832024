import { OrderDetailForThankyouFragment } from "~/types/type.generated"

type ArgFbq = {
  content_name: string
  content_ids: string[] | string
  content_type: string
  value?: number
  currency?: "JPY"
}

export type EventName =
  | "Purchase"
  | "F2Purchase"
  | "ViewContent"
  | "AddToCart"
  | "ConversionByFoot"
  | "ConversionByWork"
  | "ConversionBySleep"

const trackCustomEvents = ["ConversionByFoot", "ConversionByWork", "ConversionBySleep", "F2Purchase"]

export const useFB = () => {
  const FBPixelEventPush = (
    _eventId: string,
    _contentType: string,
    _eventName: EventName,
    _contentName: string,
    _contentIds: string[] | string,
    _totalPrice?: number,
  ) => {
    if (typeof (window as any).fbq === "function") {
      const trackName = trackCustomEvents.includes(_eventName) ? "trackCustom" : "track"
      const argFbq: ArgFbq = {
        content_name: _contentName,
        content_ids: _contentIds,
        content_type: _contentType,
      }
      if (_totalPrice) {
        argFbq.value = _totalPrice
        argFbq.currency = "JPY"
      }
      ;(window as any).fbq(trackName, _eventName, argFbq, { eventID: _eventId })
    }
  }

  const FBCApiEventPush = async (
    _eventId: string,
    _eventName: EventName,
    _contentName: string,
    _userAgent: string,
    _url: string,
    _order?: Omit<OrderDetailForThankyouFragment, "_id">,
  ) => {
    try {
      await $fetch("/api/fbc", {
        method: "POST",
        body: {
          event_id: _eventId,
          event_name: _eventName,
          content_name: _contentName,
          user_agent: _userAgent,
          url: _url,
          order: _order,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    FBPixelEventPush,
    FBCApiEventPush,
  }
}
