export const rtbPush = (_tagSuffix = "", _type = ""): void => {
  try {
    ;(function () {
      const prefix = "asia."
      const hash = "wbBEICgiDz20ESueviAC"
      const rtbhTags = []
      rtbhTags.push("pr_" + hash + _tagSuffix)
      // eslint-disable-next-line prefer-const
      let key = "__rtbhouse.lid"
      let lid = window.localStorage.getItem(key)
      if (!lid) {
        lid = ""
        const pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        for (let i = 0; i < 20; i++) lid += pool.charAt(Math.floor(Math.random() * pool.length))
        window.localStorage.setItem(key, lid)
      }
      rtbhTags.push("pr_" + hash + "_lid_" + lid)
      // eslint-disable-next-line prefer-const
      let ifr = document.createElement("iframe")
      // eslint-disable-next-line prefer-const
      let sr = encodeURIComponent(document.referrer ? document.referrer : "")
      // eslint-disable-next-line prefer-const
      let su = encodeURIComponent(document.location.href ? document.location.href : "")
      let ifrSrc = "https://" + prefix + "creativecdn.com/tags?type=iframe"
      // eslint-disable-next-line prefer-const
      let tmstmp = encodeURIComponent("" + Date.now())
      for (let i = 0; i < rtbhTags.length; i++) {
        ifrSrc += "&id=" + encodeURIComponent(rtbhTags[i])
      }
      ifrSrc += "&su=" + su + "&sr=" + sr + "&ts=" + tmstmp
      if (_type === "thankyou") ifrSrc += "&cd=default&su=" + su + "&sr=" + sr + "&ts=" + tmstmp
      ifr.setAttribute("src", ifrSrc)
      ifr.setAttribute("width", "1")
      ifr.setAttribute("height", "1")
      ifr.setAttribute("scrolling", "no")
      ifr.setAttribute("frameBorder", "0")
      ifr.setAttribute("style", "display:none")
      ifr.setAttribute("referrerpolicy", "no-referrer-when-downgrade")
      if (document.body) document.body.appendChild(ifr)
    })()
  } catch (e) {
    console.error(e)
  }
}
